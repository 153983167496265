import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { Root } from 'templates/layout'
import { CtaButton, CtaLink, RegularText } from 'components/design'
import { ImageGrid, Rimg } from 'components/images'
import { InlineFaqSection } from 'templates/faq'
import {
	FullScreenSwiper,
	buildFullScreenSwiperAnimation,
} from 'components/full-screen-swiper'
import { ReadyToStart } from 'templates/home/slides/ready-to-start'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import RVideo from '../../components/images/r-video'
gsap.registerPlugin(ScrollTrigger)

export default function ServicePageTpl({
	hero,
	numbers,
	works_heading,
	works,
	steps_heading,
	steps,
	partners,
	bottom_collage,
	next_services,
	uid,
	show_partner_logos,
	faq_categories,
	gridToDisplay,
	swiperData,
	isAnimationService,
	link1ToDisplay,
}) {
	const mobileVideosRef = useRef([])

	useEffect(() => {
		if (window.innerWidth > 1023) {
			return
		}
		// const triggerSection = document.querySelector('#m-pwu-swiper'),
		const stMob = ScrollTrigger.create({
			trigger: '#m-pwu-swiper',
			start: 'bottom bottom-=100px',
			end: 'bottom top',
			onToggle: ({ isActive }) => {
				mobileVideosRef.current.length &&
					mobileVideosRef.current.forEach((node) => {
						isActive ? node.play() : node.pause()
					})
			},
		})

		return () => stMob.kill()
	}, [])

	useEffect(() => {
		if (window.innerWidth < 1024) {
			document.querySelector('#r2s-container').style.display = 'none'
			return
		}
		const stickyHeight =
			document.querySelector('#bottom-sticky-panel')?.clientHeight || 70

		document.querySelector('#r2s-container').style.marginBottom = `-${stickyHeight}px`

		const st = ScrollTrigger.create({
			trigger: '#service-swiper-section',
			start: 'top top',
			pin: true,
			scrub: true,
			// markers: true,
			end: '+=300% bottom',
			animation: buildFullScreenSwiperAnimation(swiperData.blocks.length),
		})

		gsap.set('#ready-to-start-wrapper', { autoAlpha: 1 }, 0)
		const cubeSt = ScrollTrigger.create({
			trigger: '#r2s-container',
			start: 'top bottom',
			end: '+=150% bottom',
			// markers: true,
			onEnter: () => {
				gsap.getById('infinite-cube-tl').resume()
			},
			onLeaveBack: () => {
				gsap.getById('infinite-cube-tl').pause()
			},
		})

		return () => {
			st.kill()
			cubeSt.kill()
		}
	})
	return (
		<Root
			hero={hero}
			desktopCtaVariant="hidden"
			desktopWordmarkColor="text-white"
			mainFullWidth
		>
			<Numbers numbers={numbers} />
			<div className="ml-screen-step mt-14 w-screen-step-14 lg:ml-screen-step-5 lg:mt-40 lg:w-screen-step-12">
				<h5 className="opacity-25">See for yourself</h5>
				<h2 className="mt-2 lg:mt-4">{works_heading}</h2>
			</div>

			<ImageGrid
				grid={gridToDisplay}
				isAnimationService={isAnimationService}
				link1ToDisplay={link1ToDisplay}
			/>

			<section
				id="service-swiper-section"
				className="relative hidden h-screen w-full lg:mt-20 lg:block"
			>
				<FullScreenSwiper {...swiperData} addCtaLink />
			</section>
			<section className="relative mt-14 block w-full lg:hidden">
				<h5 className="ml-screen-step opacity-25">{swiperData.subheading}</h5>
				<h2
					id="pwu-h2"
					className="mb-8 mt-2 ml-screen-step font-headings text-2xl font-semibold leading-none lg:text-6xl lg:leading-none"
				>
					{swiperData.heading}
				</h2>

				<div className="scrollbar-hidden h-auto overflow-x-auto">
					<div id="m-pwu-swiper" className="flex flex-nowrap pr-screen-step">
						{swiperData.blocks.map(
							(
								{ heading, text, image, video, button_link, button_text },
								index
							) => (
								<div
									className={`flex w-screen-step-10 flex-none flex-col ${
										index === 0
											? 'ml-screen-step'
											: 'ml-screen-step-2'
									}`}
									key={heading}
								>
									<div className="h-screen-step-7 w-screen-step-8">
										{video ? (
											<RVideo
												videoRef={(node) =>
													node &&
													mobileVideosRef.current.indexOf(
														node
													) === -1 &&
													mobileVideosRef.current.push(node)
												}
												video={video}
												position={18}
												mobilePosition={6}
												className="h-full w-full object-cover"
												id="fsw-video-1"
											/>
										) : (
											<Rimg
												image={image}
												position={8}
												mobilePosition={8}
												className="h-full w-full object-cover"
											/>
										)}
									</div>
									<h3 className="mt-4 w-3/4 font-headings text-xl font-semibold leading-tight">
										{heading}
									</h3>
									<div
										className={`regular-text mt-4 flex grow flex-col justify-between text-sm leading-relaxed text-pale lg:text-base lg:leading-relaxed`}
									>
										{text}

										{button_link && button_text && (
											<CtaLink
												to={button_link}
												className="mt-6 w-40 sm:w-56 lg:!w-screen-step-5"
												label={button_text}
												id={`service-desktop-brief-link-${
													index + 1
												}`}
											/>
										)}
									</div>
									{/*{index === 0 ? (*/}
									{/*	<CtaLink*/}
									{/*		to="/brief/new/"*/}
									{/*		className="mt-6"*/}
									{/*		label="Fill the brief"*/}
									{/*		id="service-mobile-brief-link"*/}
									{/*	/>*/}
									{/*) : null}*/}
								</div>
							)
						)}
					</div>
				</div>
			</section>

			{show_partner_logos && (
				<>
					<div className="ml-screen-step mt-14 w-screen-step-14 lg:ml-screen-step-5 lg:mt-40 lg:w-screen-step-12">
						<h5 className="opacity-25">Join the leaders</h5>
						<h2 className="mt-2 lg:mt-4">Our partners</h2>
					</div>
					<div className="mx-auto mt-8 grid w-screen-step-14 grid-cols-3 gap-x-[calc(2.5*var(--screen-grid-step))] gap-y-8 lg:mt-20 lg:w-screen-step-26 lg:grid-cols-5 lg:gap-x-[calc(3.375*var(--screen-grid-step))]">
						{partners.map(({ logo }) => (
							<Rimg
								image={logo}
								position={2.5}
								mobilePosition={3}
								className={'opacity-30'}
								key={logo.url}
							/>
						))}
					</div>
				</>
			)}

			{faq_categories?.length && faq_categories[0]?.is_visible && (
				<>
					<div className="mx-auto mt-4 px-content-step pt-16 lg:mt-28 lg:w-screen-step-28 lg:pt-0">
						<InlineFaqSection categories={faq_categories.slice(0, 1)} />
					</div>

					<div className="mx-auto mt-10 flex w-full flex-wrap gap-y-screen-step gap-x-screen-step px-screen-step lg:w-screen-step-28 lg:flex-nowrap">
						<CtaLink
							to="/contacts/"
							label="Send a message"
							className="!w-screen-step-8 lg:!w-screen-step-5"
						/>

						<CtaButton
							label="Schedule a call"
							id="schedule-a-call-service"
							variation="white"
							className={`!w-screen-step-8 lg:!w-screen-step-5`}
							onClick={() => {
								/* global Calendly */
								Calendly.showPopupWidget(
									'https://calendly.com/cuubstudio/30min'
								)
							}}
						/>
					</div>
				</>
			)}

			<div
				className="mt-12 flex flex-wrap text-white lg:mt-28 lg:flex-nowrap"
				id="next-services"
			>
				{next_services.map(({ title, path, image }, index) => (
					<Link
						className="flex aspect-[15/8] w-full items-center justify-center bg-gray-800/75 bg-cover bg-center text-center lg:relative lg:block lg:aspect-square lg:w-1/2 lg:text-left"
						style={{
							backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('${
								image?.fluid?.src || image.url
							}')`,
						}}
						to={path}
						key={path}
					>
						<h5 className="hidden lg:absolute lg:bottom-36 lg:left-1/2 lg:block lg:-translate-x-1/2">
							{index ? 'Next' : 'Previous'} service
						</h5>
						<h2 className="lg:absolute lg:bottom-20 lg:left-1/2 lg:-translate-x-1/2">
							{title}
						</h2>
					</Link>
				))}
			</div>
			<section className="relative h-screen w-full" id="r2s-container">
				<ReadyToStart />
			</section>
		</Root>
	)
}

const Numbers = ({ numbers }) => (
	<div className="mt-14 flex w-full flex-col gap-y-8 lg:mx-auto lg:mt-40 lg:w-screen-step-28 lg:flex-row lg:justify-center lg:gap-y-0">
		{numbers.map(({ number, paragraph, subheading }, index) => (
			<div className={`mx-screen-step lg:w-screen-step-7`} key={subheading}>
				<h3>
					{number} {subheading}
				</h3>
				<RegularText className="mt-4">{paragraph}</RegularText>
			</div>
		))}
	</div>
)

// const Steps = ({ heading, steps }) => (
// 	<div className="service-steps-container">
// 		<h2>{heading}</h2>
// 		<div className="mt-8 grid gap-12 lg:w-11/12 lg:grid-cols-2">
// 			{steps.map(({ step_heading, step_text }, index) => (
// 				<div key={step_heading}>
// 					<h3 className="opacity-50">0{index + 1}</h3>
// 					<h3 className="mt-1">{step_heading}</h3>
// 					<RegularText className="mt-4">{step_text}</RegularText>
// 				</div>
// 			))}
// 			<Link
// 				className="bg-black py-4 text-center font-bold uppercase tracking-px text-white transition-colors duration-200 hover:bg-accent"
// 				to="/brief/new/"
// 			>
// 				Fill in the brief
// 			</Link>
// 		</div>
// 	</div>
// )

// const BottomCollage = ({ images }) => {
// 	const refs = images.map(() => createRef())

// 	useEffect(() => {
// 		if (window.innerWidth < 1024) {
// 			return
// 		}
// 		refs.forEach((ref) => {
// 			if (!ref?.current?.clientHeight) {
// 				return
// 			}
// 			const computed = getComputedStyle(ref.current)
// 			if (computed?.maxHeight && computed.maxHeight !== 'none') {
// 				return
// 			}

// 			ref.current.style.maxHeight = `${ref.current.clientHeight}px`
// 		})
// 	})

// 	return images.map(({ image: { url } }, index) => (
// 		<div
// 			className="inline-block h-full min-h-[20rem] w-1/3 bg-cover bg-center"
// 			style={{ backgroundImage: `url('${url}')` }}
// 			key={url}
// 			ref={refs[index]}
// 		/>
// 	))
// }

// Test data
// const testSwiperData = JSON.parse(JSON.stringify(swiperData))
// const videoUrl =
// 	'https://cuub.cdn.prismic.io/cuub/f8ef2270-1c60-42f9-afc2-e230b00823e9_home-hero.mp4'
// testSwiperData.blocks[0].video = {
// 	dimensions: {
// 		height: 1896,
// 		width: 1896,
// 	},
// 	url: videoUrl,
// }
// testSwiperData.blocks[0].image = ''
// testSwiperData.blocks[1].video = {
// 	dimensions: {
// 		height: 1896,
// 		width: 1896,
// 	},
// 	url: videoUrl,
// }
// testSwiperData.blocks[1].image = ''
// testSwiperData.blocks[2].video = {
// 	dimensions: {
// 		height: 1896,
// 		width: 1896,
// 	},
// 	url: videoUrl,
// }
// testSwiperData.blocks[2].image = ''
// testSwiperData.blocks[3].video = {
// 	dimensions: {
// 		height: 1896,
// 		width: 1896,
// 	},
// 	url: videoUrl,
// }
// testSwiperData.blocks[3].image = ''
