import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'utils/link-resolver'
import getFluidSrc from 'utils/get-fluid-src'
import ServicePageTpl from 'templates/services/page-v2'

const ServicePage = ({ data: { prismicServicePageV2, prismicHomePage }, path }) => {
	const { hero_image, hero_video, hero_mobile_video, heading_1, works_grid } =
		prismicServicePageV2.data

	// Keep in sync with utils/portfolio-grid
	const gridToDisplay = works_grid
		.filter(({ is_visible }) => is_visible)
		.map(
			({
				image_1,
				image_2,
				image_3,
				video_1,
				video_2,
				video_3,
				vertical_index,
			}) => {
				const items = []
				if (image_1?.url || video_1?.url) {
					items.push({ image: image_1, video: video_1 })
				}
				if (image_2?.url || video_2?.url) {
					items.push({ image: image_2, video: video_2 })
				}
				if (image_3?.url || video_3?.url) {
					items.push({ image: image_3, video: video_3 })
				}

				let component
				switch (items.length) {
					case 1:
						component = 'single'
						break
					case 2:
						component = 'double'
						break
					case 3:
						component = 'triple'
						break
					default:
						throw new Error('Unrealistic grid group length')
				}

				return { component, items, verticalIndex: vertical_index - 1 }
			}
		)
	const link1ToDisplay = works_grid.length && works_grid[0]?.link_1?.url
	const content = {
		hero: {
			h1: heading_1,
			img_url: getFluidSrc(hero_image),
			image: hero_image,
			video: hero_video,
			mobile_video: hero_mobile_video,
		},
		...prismicServicePageV2.data,
		partners: prismicHomePage.data.client_logos,
		uid: prismicServicePageV2.uid,
		gridToDisplay,
		link1ToDisplay,
		swiperData: {
			heading: prismicServicePageV2.data.steps_heading,
			subheading: prismicServicePageV2.data.steps_subheading,
			blocks: prismicServicePageV2.data.steps.map(
				({
					step_heading: heading,
					step_text: text,
					image,
					video_link: { id, url },
					button_link,
					button_text,
				}) => ({
					heading,
					text,
					image,
					button_link,
					button_text,
					video: url,
				})
			),
		},
	}

	return (
		<>
			<ServicePageTpl
				{...content}
				isAnimationService={path.includes('animation')}
			/>
			{path.includes('product') && (
				<Helmet>
					<meta name="robots" content="noindex" />
				</Helmet>
			)}
		</>
	)
}

export const query = graphql`
	query servicePageV2Query($uid: String) {
		prismicServicePageV2(uid: { eq: $uid }) {
			_previewable
			uid
			data {
				hero_image {
					alt
					url
					dimensions {
						height
						width
					}
					fluid(maxWidth: 2000) {
						src
					}
				}
				hero_video {
					url
				}
				hero_mobile_video {
					url
				}
				heading_1
				numbers {
					number
					paragraph
					subheading
				}
				works_heading
				steps_subheading
				steps_heading
				steps {
					step_heading
					step_text
					button_text
					button_link
					video_link {
						id
						url
					}
					image {
						alt
						url
						dimensions {
							height
							width
						}
					}
				}
				works_grid {
					image_1 {
						alt
						dimensions {
							height
							width
						}
						url
					}
					image_2 {
						alt
						dimensions {
							height
							width
						}
						url
					}
					image_3 {
						alt
						url
						dimensions {
							height
							width
						}
					}
					video_1 {
						url
					}
					video_2 {
						url
					}
					video_3 {
						url
					}
					is_visible
					vertical_index
					link_1 {
						url
					}
				}
				works_heading
				works_subheading
				show_partner_logos
				faq_categories {
					category {
						...PrismicFaqCategoryFragment
					}
					is_visible
				}
				next_services {
					title
					path
					image {
						alt
						url
						fluid(maxWidth: 1920) {
							src
						}
						dimensions {
							height
							width
						}
					}
				}
			}
		}
		prismicHomePage {
			data {
				client_logos {
					logo {
						alt
						url
						dimensions {
							height
							width
						}
					}
				}
			}
		}
	}
`

export default withPrismicPreview(ServicePage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
	},
])
