import React from 'react'
// import { Link } from 'gatsby'
// import { PrismicRichText } from '@prismicio/react'
import gsap from 'gsap'
import { CtaLink } from 'components/design'
import { Rimg } from 'components/images'
import RVideo from './images/r-video'

const SLIDE_INDEX_TOPS = ['45%', '51.5%', '58%', '64.5%']

const FullScreenSwiper = ({ heading, subheading, blocks, addCtaLink }) => (
	<section
		id="full-screen-swiper-wrapper"
		className="absolute top-0 h-screen w-full overflow-hidden bg-white"
		// style={{ visibility: 'hidden' }}
	>
		<div className="relative flex h-full w-full">
			<h2 id="fsw-h2" className="absolute top-40 left-[14%] z-[4] w-1/4">
				{heading}
			</h2>

			{blocks.map(
				({ heading, text, image, video, button_link, button_text }, index) => (
					<React.Fragment key={heading}>
						<h3
							id={`fsw-h3-${index + 1}`}
							className="absolute left-[14%] w-1/4"
							style={
								index > 0
									? { visibility: 'hidden', top: '45%' }
									: { top: '45%' }
							}
						>
							{heading}
						</h3>
						<div
							id={`fsw-paragraph-${index + 1}`}
							className={`regular-text absolute left-[14%] w-1/4 text-sm leading-relaxed text-pale lg:text-base lg:leading-relaxed`}
							style={{
								visibility: index > 0 ? 'hidden' : 'inherit',
								top: '50%',
							}}
						>
							{text}
							{button_link && button_text && (
								<CtaLink
									to={button_link}
									className="mt-24 lg:!w-screen-step-5"
									label={button_text}
									id={`service-desktop-brief-link-${index + 1}`}
								/>
							)}
						</div>

						{/*{index === 0 && addCtaLink ? (*/}
						{/*	<CtaLink*/}
						{/*		to="/brief/new/"*/}
						{/*		className="absolute left-[14%] top-[69%] mt-6 lg:!w-screen-step-5"*/}
						{/*		label="Fill the brief"*/}
						{/*		id="service-desktop-brief-link"*/}
						{/*	/>*/}
						{/*) : null}*/}
					</React.Fragment>
				)
			)}

			{blocks.map((_, index) => (
				<div
					id={`fsw-number-${index + 1}`}
					key={`fsw-number-${index + 1}`}
					className="absolute left-screen-step flex flex-col font-headings text-2xl font-semibold leading-tight"
					style={{
						opacity: index === 0 ? 1 : index === 1 ? 0.25 : 0.1,
						top: SLIDE_INDEX_TOPS[index],
					}}
				>
					0{index + 1}
				</div>
			))}

			{blocks.map((block, index) => {
				const imageKey = `fsw-img-${index + 1}`
				return (
					<div
						id={`${imageKey}-wrapper`}
						key={imageKey}
						className={
							(index === 0 ? 'top-0 ' : '') +
							`absolute left-1/2 h-full w-1/2 overflow-hidden`
						}
						style={index === 0 ? {} : { visibility: 'hidden', top: '100%' }}
					>
						{block?.video ? (
							<div id={imageKey} className="h-full w-full">
								<RVideo
									video={block.video}
									position={18}
									mobilePosition={6}
									className="h-full w-full object-cover"
									id={`fsw-video-${index + 1}`}
								/>
							</div>
						) : (
							<div id={imageKey} className="h-full w-full">
								<Rimg
									image={block.image}
									position={18}
									mobilePosition={6}
									className="h-full w-full object-cover"
								/>
							</div>
						)}
					</div>
				)
			})}
		</div>
	</section>
)

const buildFullScreenSwiperAnimation = (count) =>
	gsap
		.timeline({ id: 'fsw-tl', defaults: { ease: 'none' } })
		.call(
			() => {
				const video1 = document.querySelector('#fsw-video-1')
				video1 && video1.play()
			},
			null,
			1
		)
		// .set('#full-screen-swiper-wrapper', {
		// 	autoAlpha: 1,
		// 	// yPercent: 100,
		// })
		// .to('#full-screen-swiper-wrapper', {
		// 	yPercent: 0,
		// 	duration: 2.5,
		// })
		// .from('#fsw-h2', { top: '60%', autoAlpha: 0, duration: 1 }, 1.5)
		// .from('#fsw-h3-1', { top: '100%', autoAlpha: 0, duration: 1 }, 1.75)
		// .from('#fsw-number-1', { top: '100%', duration: 1 }, '<')
		// .from('#fsw-paragraph-1', { top: '100%', autoAlpha: 0, duration: 1 }, 2)
		// .from('#fsw-number-2', { top: '100%', duration: 1 }, '<')
		// .from('#fsw-number-3', { top: '100%', duration: 1 }, 2.25)
		// .from('#fsw-img-1-wrapper', { autoAlpha: 0, duration: 1.25 }, 0.25)
		// .from('#fsw-img-1', { scale: 1.5, duration: 3.5 }, 0.5)
		.to({}, { duration: 0.75 }) // pause to breathe
		.addLabel('fsw2Start')
		// .call(
		// 	() => {
		// 		const video = document.querySelector('#fsw-video-1')
		// 		video && video.play()
		// 	},
		// 	null,
		// 	'=+1'
		// )
		.to('#fsw-h3-1', { top: '25%', autoAlpha: 0, duration: 1 }, `fsw2Start`)
		.to('#fsw-paragraph-1', { top: '31.5%', autoAlpha: 0, duration: 1 }, '<')
		.from(
			'#fsw-h3-2',
			{ top: '75%', duration: 1, immediateRender: false },
			`fsw2Start+=${0.25}`
		)
		.to('#fsw-h3-2', { autoAlpha: 1, duration: 1 }, '<')
		.from(
			'#fsw-paragraph-2',
			{ top: '83%', duration: 1, immediateRender: false },
			`fsw2Start+=${0.5}`
		)
		.to('#fsw-paragraph-2', { autoAlpha: 1, duration: 1 }, '<')
		.call(
			() => {
				const video2 = document.querySelector('#fsw-video-2'),
					video1 = document.querySelector('#fsw-video-1')
				video2 && video2.play()
				video1 && video1.pause()
			},
			null,
			'>'
		)
		.to('#fsw-number-1', { opacity: 0.25, duration: 1 }, `fsw2Start+=${0.25}`)
		.to('#fsw-number-2', { opacity: 1, duration: 1 }, `fsw2Start+=${0.5}`)
		.to('#fsw-number-3', { opacity: 0.25, duration: 1 }, `fsw2Start+=${0.75}`)
		.to('#fsw-img-1', { scale: 0.7, duration: 1.5 }, `fsw2Start`)
		.to('#fsw-img-1-wrapper', { autoAlpha: 0, duration: 1.5 }, `fsw2Start`)
		.set('#fsw-img-2-wrapper', { autoAlpha: 1 }, `fsw2Start`)
		.to('#fsw-img-2-wrapper', { top: 0, duration: 1.5 }, `fsw2Start`)
		.from(
			'#fsw-img-2',
			{ scale: 1.5, duration: 2, immediateRender: false },
			`fsw2Start`
		)
		// .to({}, { duration: 1 }) // pause to breathe
		.add(
			count && count < 3
				? []
				: gsap
						.timeline({ defaults: { ease: 'none' } })
						.addLabel('fsw3Start')
						.to(
							'#fsw-h3-2',
							{ top: '25%', autoAlpha: 0, duration: 1 },
							`fsw3Start`
						)
						.to(
							'#fsw-paragraph-2',
							{ top: '31.5%', autoAlpha: 0, duration: 1 },
							'<'
						)
						.from(
							'#fsw-h3-3',
							{ top: '75%', duration: 1, immediateRender: false },
							`fsw3Start+=${0.25}`
						)
						.to('#fsw-h3-3', { autoAlpha: 1, duration: 1 }, '<')
						.from(
							'#fsw-paragraph-3',
							{ top: '83%', duration: 1, immediateRender: false },
							`fsw3Start+=${0.5}`
						)
						.to('#fsw-paragraph-3', { autoAlpha: 1, duration: 1 }, '<')
						.call(
							() => {
								const video3 = document.querySelector('#fsw-video-3'),
									video2 = document.querySelector('#fsw-video-2')

								video3 && video3.play()
								video2 && video2.pause()
							},
							null,
							'>'
						)
						.to(
							'#fsw-number-1',
							{ opacity: 0.1, duration: 1 },
							`fsw3Start+=${0.25}`
						)
						.to(
							'#fsw-number-2',
							{ opacity: 0.25, duration: 1 },
							`fsw3Start+=${0.5}`
						)
						.to(
							'#fsw-number-3',
							{ opacity: 1, duration: 1 },
							`fsw3Start+=${0.75}`
						)
						.to(
							'#fsw-number-4',
							{ opacity: 0.25, duration: 1 },
							`fsw3Start+=${0.75}`
						)
						.to('#fsw-img-2', { scale: 0.7, duration: 1.5 }, `fsw3Start`)
						.to(
							'#fsw-img-2-wrapper',
							{ autoAlpha: 0, duration: 1.5 },
							`fsw3Start`
						)
						.set('#fsw-img-3-wrapper', { autoAlpha: 1 }, `fsw3Start`)
						.to('#fsw-img-3-wrapper', { top: 0, duration: 1.5 }, `fsw3Start`)
						.from(
							'#fsw-img-3',
							{ scale: 1.5, duration: 2, immediateRender: false },
							`fsw3Start`
						)
		)
		.add(
			count && count < 4
				? []
				: gsap
						.timeline({ defaults: { ease: 'none' } })
						.addLabel('fsw4Start')
						.to(
							'#fsw-h3-3',
							{ top: '25%', autoAlpha: 0, duration: 1 },
							`fsw4Start`
						)
						.to(
							'#fsw-paragraph-3',
							{ top: '31.5%', autoAlpha: 0, duration: 1 },
							'<'
						)
						.from(
							'#fsw-h3-4',
							{ top: '75%', duration: 1, immediateRender: false },
							`fsw4Start+=${0.25}`
						)
						.to('#fsw-h3-4', { autoAlpha: 1, duration: 1 }, '<')
						.call(
							() => {
								const video4 = document.querySelector('#fsw-video-4'),
									video3 = document.querySelector('#fsw-video-3')

								video4 && video4.play()
								video3 && video3.pause()
							},
							null,
							'>'
						)
						.from(
							'#fsw-paragraph-4',
							{ top: '83%', duration: 1, immediateRender: false },
							`fsw4Start+=${0.5}`
						)
						.to('#fsw-paragraph-4', { autoAlpha: 1, duration: 1 }, '<')
						.to(
							'#fsw-number-1',
							{ opacity: 0.1, duration: 1 },
							`fsw4Start+=${0.25}`
						)
						.to(
							'#fsw-number-2',
							{ opacity: 0.1, duration: 1 },
							`fsw4Start+=${0.25}`
						)
						.to(
							'#fsw-number-3',
							{ opacity: 0.25, duration: 1 },
							`fsw4Start+=${0.5}`
						)
						.to(
							'#fsw-number-4',
							{ opacity: 1, duration: 1 },
							`fsw4Start+=${0.75}`
						)
						.to('#fsw-img-3', { scale: 0.7, duration: 1.5 }, `fsw4Start`)
						.to(
							'#fsw-img-2-wrapper',
							{ autoAlpha: 0, duration: 1.5 },
							`fsw4Start`
						)
						.set('#fsw-img-4-wrapper', { autoAlpha: 1 }, `fsw4Start`)
						.to('#fsw-img-4-wrapper', { top: 0, duration: 1.5 }, `fsw4Start`)
						.from(
							'#fsw-img-4',
							{ scale: 1.5, duration: 2, immediateRender: false },
							`fsw4Start`
						)
		)
// .to({}, { duration: 1 }) // pause to breathe
// .call(() => stickyVariantSetter('line', false, true), null, 1)
// .call(
// 	() => {
// 		stickyVariantSetter('logo', 'text-black', 'text-white')
// 		stickyVariantSetter('menu-text', 'text-black', 'text-white')
// 		stickyVariantSetter(
// 			'menu-bg',
// 			'bg-white transition-colors duration-500',
// 			'bg-transparent'
// 		)

// 		const videoEl = document.querySelector('#ani-video')

// 		videoEl.paused ? videoEl.play() : videoEl.pause()
// 	},
// 	null,
// 	2.5
// )

export { FullScreenSwiper, buildFullScreenSwiperAnimation }
