import React from 'react'

const DESKTOP_WIDTH_UNITS = {
	pillar: 4,
	column: 8,
	columnWithGutter: 12,
	verticalImage: 16,
	columnToColumn: 18,
	gutterToGutter: 26,
	horizontalImage: 30,
	fullWidth: 34,
	// TODO full screen image
}
const DESKTOP_GRID_COLUMNS = 36
const DEKSTOP_SCREENS = [1024, 1280, 1440, 1536, 1680, 1920, 2540]
const MOBILE_GRID_COLUMNS = 16
const MOBILE_IMAGE_WIDTHS = [375, 750, 1125]

const buildDesktopWidth = (multiplier) => {
	const regular = DEKSTOP_SCREENS.map((vw) =>
		Math.ceil((multiplier * vw) / DESKTOP_GRID_COLUMNS)
	)
	const retina = regular.map((w) => 2 * w)

	return [...regular, ...retina]
}
const buildMobileWidth = (multiplier) => {
	const MOBILE_SCREEN_WIDTH = 424

	const widths = [Math.ceil((multiplier * MOBILE_SCREEN_WIDTH) / MOBILE_GRID_COLUMNS)]
	widths.push(widths[0] * 2)
	widths.push(widths[0] * 3)

	return widths
}

const buildVideoConfig = (position, imgRealWidth, mobilePosition) => {
	const multiplier =
		typeof position === 'number' ? position : DESKTOP_WIDTH_UNITS[position]

	const desktopVw = Math.floor((1000000 * multiplier) / DESKTOP_GRID_COLUMNS) / 10000
	const mobileVw = mobilePosition
		? Math.floor((1000000 * mobilePosition) / MOBILE_GRID_COLUMNS) / 10000
		: 87.5
	const mobileWidths = mobilePosition
		? buildMobileWidth(mobilePosition)
		: MOBILE_IMAGE_WIDTHS

	const widths = [...mobileWidths, ...buildDesktopWidth(multiplier)]
		.filter((w) => w <= imgRealWidth)
		.sort((a, b) => a - b)

	// Include original width when the image has lower quality and does not fit all viewports
	if (
		widths[widths.length - 1] < imgRealWidth &&
		imgRealWidth < (34 / 36) * DEKSTOP_SCREENS[DEKSTOP_SCREENS.length - 1] * 2 // maximum retina desktop image
	) {
		widths.push(imgRealWidth)
	}

	return { widths, desktopVw, mobileVw }
}

export default function RVideo({
	className,
	position,
	video,
	mobilePosition,
	style,
	id,
	videoRef,
}) {
	const dimensions = { height: 1896, width: 1896 }
	if (!position || (typeof position !== 'number' && !DESKTOP_WIDTH_UNITS[position])) {
		throw new Error('Set an appropriate position')
	}

	const { desktopVw, mobileVw } = buildVideoConfig(
		position,
		dimensions.width,
		mobilePosition
	)

	const videoProps = {
		className,
		ref: videoRef && videoRef,
		id,
		sizes: `(max-width: 1024px) ${mobileVw}vw, ${desktopVw}vw`,
		src: video,
		style: {
			...style,
			aspectRatio: `${dimensions.width} / ${dimensions.height}`,
		},
	}

	return <video {...videoProps} loop muted playsInline preload="metadata" />
}
